import { format, lastDayOfMonth, subMonths } from 'date-fns'
import portugueseLocale from 'date-fns/locale/pt-BR'
import { defaultInterpreterSpeed } from './constants'
import { ISignLanguageEditorData, ITableCuesEntry } from './types'

/**
 * converte number na moeda real
 * Retorna uma string legível que representa o número usando o código do idioma do ambiente.
 * @param param
 */
export const coinPtbr = (param: number) => {
  const number = new Number(param)
  return number.toLocaleString('pt-br', {
    maximumFractionDigits: 0,
  })
}

/**
 * Formata os números para o padrão brasileiro
 * @param value String ou número a ser formatado
 */
export const ptBrNumberFormatter = (value: any, posfix?: boolean) => {
  const intValue = parseInt(value)

  if (intValue >= 10000 && posfix) {
    return numberFormatter(intValue)
  }

  const formatted = intValue.toLocaleString()
  return formatted.replace(/,/gi, '.')
}

/**
 * Formata numeral com posfixo K, M ou B
 * @param value String ou número a ser formatado
 */
export const numberFormatter = (value: any) => {
  const intValue = parseInt(value)

  if (intValue >= 1000000000) {
    return (intValue / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G'
  }
  if (intValue >= 1000000) {
    return (intValue / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
  }
  if (intValue >= 1000) {
    return (intValue / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
  }
  return intValue
}

/**
 * cores padrões utilizado no projeto
 */
export const colors = {
  blueBaby: '#00BFA5',
  indigo: '#5c6bc0',
  lightblue: '#29b6f6',
  orange: '#fb8c00',
  teal: '#1de9b6',
  yellow: '#fbc02d',
  yellowLight: '#ffe140',
  red: '#e14e3f',
  green: '#53c86e',
}

/**
 * array de corres utilizado no gráfico pizza
 */
export const optionOne = [
  '#ffccbc',
  '#ffab91',
  '#ff7043',
  '#ff6d00',
  '#ff9100',
  '#ffab40',
  '#ffd180',
  '#fff176',
  '#ffea00',
  '#80d8ff',
  '#40c4ff',
  '#00b0ff',
  '#0091ea',
  '#c5cae9',
  '#9fa8da',
  '#7986cb',
  '#5c6bc0',
  '#a7ffeb',
  '#64ffda',
  '#1de9b6',
  '#00bfa5',
  '#9e9d24',
  '#c0ca33',
  '#cddc39',
  '#e6ee9c',
  '#f44336',
  '#e57373',
  '#ef9a9a',
]

/**
 * array com todos os meses do ano
 */
export const months = [
  'JAN',
  'FEV',
  'MAR',
  'ABR',
  'MAI',
  'JUN',
  'JUL',
  'AGO',
  'SET',
  'OUT',
  'NOV',
  'DEZ',
]

/**
 * cores das barras dos gráficos
 */
export const colorBar: any = [
  colors.yellow,
  colors.lightblue,
  colors.indigo,
  colors.teal,
  colors.orange,
  colors.blueBaby,
]

/**
 * Retorna o ano atual
 */
export const yearCurrent = () => {
  const date = new Date()
  const year =
    date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear()
  return year
}

export const isQuarterEnding = (month: number | string) => {
  const quarterEndings = [3, 6, 9, 12, '3', '6', '9', '12', '03', '06', '09']

  return quarterEndings.includes(month)
}

/**
 * Função que formata as datas para int
 * @param date String que representa uma data
 */
export const formatDateToInt = (date: string) => {
  // Transforma ano em int
  const year = parseInt(date.slice(0, 4))

  // Transforma mês em int
  let month
  if (date[4] == '0') {
    month = parseInt(date[5])
  } else month = parseInt(date.slice(4, 6))

  let day
  if (date.slice(6) != '') day = date.slice(6)
  else day = 1

  return [year, month - 1, day]
}

/**
 * Formata as strings para um objeto Date
 * @param period String de uma data. Ex: "20010101"
 */
export const formatPeriodToDate = (period: string) => {
  const [year, month, day] = formatDateToInt(period)

  return new Date(year as number, month as number, day as number)
}

/**
 * Pega o ano formatado em ptBr de uma data
 * @param period String de uma data. Ex: "20010101"
 */
export const getPtBrMonthYear = (period: string) => {
  const date = formatPeriodToDate(period)
  return format(date, 'MM/yyyy', {
    locale: portugueseLocale,
  })
}

/**
 * Converte segundos em um objeto contendo hora, minuto, segudos e milisegundos
 * @param seconds - Segundos que serão convertidos
 */
export const secondsToTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds - hours * 3600) / 60)
  const secondsParsed = seconds - hours * 3600 - minutes * 60

  return {
    hours,
    minutes,
    seconds: secondsParsed.toFixed(3),
  }
}

/**
 * Converte as frases de legenda padrão para o formato suportado pelo pela Hand Talk
 * @param cues - Frases de legenda
 */
export const cueToSignLanguageCue = (cues: ITableCuesEntry[]) => {
  const mappedCues: ISignLanguageEditorData[] = cues.map((cue: any) => {
    const {
      startTime,
      endTime,
      text,
      reviewStatus,
      glosas,
      interpretationSpeed,
    } = cue
    const glosasFormatted = glosas
      ? glosas.map((glosa: any) => ({
          text: glosa.text,
          type: glosa.text.includes('#') ? 'simple-sign' : 'fingerspell',
          id: glosa.id || '',
        }))
      : []

    return {
      animationCodes: [],
      endTime,
      glosas: glosasFormatted,
      interpretationSpeed: interpretationSpeed || defaultInterpreterSpeed,
      startTime,
      text,
      reviewStatus: reviewStatus || 'reviewPending',
    }
  })

  return mappedCues
}
