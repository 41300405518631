import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'
import DashboardIcon from '@material-ui/icons/Dashboard'
import VideocamIcon from '@material-ui/icons/Videocam'
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { requireAuth } from '../../services/containers/AuthContainer'
type nullFn = () => void

interface IDrawerItemProps {
  to: string | nullFn
  icon: JSX.Element
  text: string
  location?: any
  history?: any
}

class DrawerListItem extends Component<IDrawerItemProps> {
  public handleClick = () => {
    const { to, history } = this.props
    if (typeof to === 'function') {
      to()
    } else {
      history.replace(to)
    }
  }

  public render() {
    const { to, icon, text, location } = this.props
    const isCurrentRoute: boolean =
      location.pathname === to ||
      (location.pathname.startsWith(to) && to !== '/')

    return (
      <ListItem
        button
        selected={isCurrentRoute}
        disabled={isCurrentRoute}
        onClick={this.handleClick}
      >
        <Tooltip title={text}>
          <ListItemIcon style={{ marginRight: -16 }}>{icon}</ListItemIcon>
        </Tooltip>
        <ListItemText primary={text} />
      </ListItem>
    )
  }
}

const DrawerList = (withAuthProps?: any) => {
  const { location, history } = withAuthProps
  let listHtml = <div />
  if (withAuthProps.auth.state.role === 'client') {
    return null;
  }
  if (withAuthProps.auth.state.role !== undefined) {
    listHtml = (
      <List component="nav" style={{ overflow: 'hidden auto' }}>
          <div>
            <DrawerListItem
              to="/unitsEconomics"
              icon={<DashboardIcon />}
              text="Units Economics"
              location={location}
              history={history}
            />
            <DrawerListItem
              to="/subscriptionsAnalytics"
              icon={<DashboardIcon />}
              text="Subscriptions Analytics"
              location={location}
              history={history}
            />
            <DrawerListItem
              to="/videos"
              icon={<VideocamIcon />}
              text="Vídeos"
              location={location}
              history={history}
            />
             <DrawerListItem
              to="/managerTokens"
              icon={<VpnKeyIcon />}
              text="Gerenciador de Tokens"
              location={location}
              history={history}
            />
          </div>
      </List>
    )
  }

  return listHtml
}
export default withRouter(requireAuth(DrawerList))
