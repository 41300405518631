export class HtError extends Error {
    public status: number
    public safeMessage: string
  
    public constructor(safeMessage: string, status = 500) {
      super(safeMessage)
      this.status = status
      this.safeMessage = safeMessage
    }
  
    public toJson = () => {
      return { status: this.status, message: this.safeMessage }
    }
  }
  
  export const erroTokenDeAnimadorObrigatorio = new HtError(
    'Token de animador Obrigatório',
    401,
  )
  
  export const erroTokenDeAnimadorInvalido = new HtError(
    'Token de animador Inválido',
    403,
  )
  
  export const getErroGlosaNaoTemCaractereUnderline = (glosa: string) =>
    new HtError(`Glosa inválida, não tem caractere "_": "${glosa}"`, 403)
  
  export const getErroGlosaNaoTemLinguaNoInicio = (glosa: string) =>
    new HtError(
      `Glosa inválida, não inicia com BZS ou outra língua: "${glosa}"`,
      403,
    )
  
  export const getErroGlosaNaoEstaEmMaiuscula = (glosa: string) =>
    new HtError(`Glosa inválida, não está em maiúscula: "${glosa}"`, 403)
  
  export const errorVttBadFormat = new HtError(
    'Nenhuma entrada de tempo localizada. Provavalmente o arquivo de legenda não é um VTT',
  )
  
  export const errorWaitForEventTimeout = new HtError(
    'O tempo limite foi excedido e nenhum evento foi chamado',
  )