import * as process from "process";

/** Tempo maxima de espera por um evento no dom */
export const waitForEventTimeout = 15000;

/** API de tradução e animação vinda do functions */
export const functionsApi =
  "https://us-central1-ht-fb-e3130.cloudfunctions.net/api/";

/** Caminho para o script do core da HT */
export const coreVersion = "1.0.12";
export const htcoreScriptUrl = `https://plugin.handtalk.me/corejs/${coreVersion}/core.min.js`;

/** Caminho para o script do ccapture */
export const ccaptureScriptUrl =
  "https://unpkg.com/ccapture.js@1.1.0/build/CCapture.all.min.js";


/** Token de acesso ao HT Recorder */
export const htRecorderToken = "894ad581fb6306c7363cb9c32a5dd9dd";

/** Token de acesso para tradução texto para glosa */
export const glosaApiToken = "99b20495349ba8dc99b4e865659eb407";

/** Valor padrão da velocidade de interpretação do HUGO */
export const defaultInterpreterSpeed = 1;
