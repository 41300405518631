import React from 'react'

import { waitForEventTimeout } from './constants'
import { errorWaitForEventTimeout } from './errors'

/**
 * Aplica funções que retornam componentes em série.
 *
 * @example const DecoratedComponent = compose(
 *  withAuth,
 *  withWidth,
 *  withStyle(style)
 * )(Component)
 * @param funcs Várias funções decoradoras de componentes (HoCs)
 */
export const compose = (...funcs: any[]) =>
  funcs.reduce((a, b) => (...args: any[]) => a(b(...args)))

/**
 * Exporta a chave de um caminho em um objeto. Um caminho são chaves concatenadas com '.' (pontos). Se não existir, retorna undefined.
 * @example const x = getKey('a.b.c', { a: {b: { c: 1 } }) // 1
 * @param keyPath - Caminho (chaves concatenadas por '.')
 * @param object - Objeto
 */
export const getKey = (keyPath: string, object: object = {}) =>
  keyPath.split('.').reduce((obj: any = {}, key) => obj[key], object)

/**
 * Verifica se um componente pode ser renderizado ou não. Se o retorno do
 *  filtro for falso, o  elemento element será retornado.
 * @param filter Função de filtro. Esta função recebe os props do elemento e
 *  deve retornat um boolean.
 * @param element Elemento a ser retornado se o filtro for falso.
 */
export const withFilter = <P extends object>(
  filter: (props: P) => boolean,
  element: React.ReactElement<any>,
) => (Comp: React.ComponentType<P>): React.FC<P> => {
  const Component = (props: P) =>
    filter(props) ? <Comp {...props} /> : element
  return Component
}

/**
 * Aguarda por um evento (uma unica vez) de um elemento.
 * Básicamente converte um listener (evento) em uma promessa com timeout.
 * @param elem - Elemento que deverá ser observado
 * @param eventName - Nome do evento
 */
export const waitForEvent = (
  elemOrWindow: HTMLElement | Window,
  eventName: string,
): Promise<{}> => {
  return new Promise((resolve, reject) => {
    elemOrWindow.addEventListener(eventName, resolve, { once: true })
    setTimeout(() => {
      return reject(errorWaitForEventTimeout)
    }, waitForEventTimeout)
  })
}

/**
 * Injeta um script no dom e aguarda o carregamento
 * @param elem - Elemento que receberá o script
 */
export const injectScript = async (
  elem: HTMLElement,
  src: string,
): Promise<any> => {
  // Cria o elemento do script
  const script: HTMLScriptElement = document.createElement('script')
  script.src = src
  elem.appendChild(script)

  // Aguarda o carregamento do script
  try {
    await waitForEvent(script, 'load')
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

/** Flag para determinar se o usuário está navegando em um browser mobile */
export let isMobileBrowser = window.window.innerWidth <= 720

addEventListener('resize', () => {
  isMobileBrowser = window.window.innerWidth <= 720
})
