// TODO: Substituir debug pelo logger do shared (pergunte ao Thadeu)
import debug from 'debug'
import React from 'react'

import { Redirect } from 'react-router-dom'
import { Container, Subscribe } from 'unstated'

import { Subtract } from 'utility-types'

import User from '../../classes/User'
import { compose, getKey, withFilter } from '../../services/utils'
import firebase, { fbAuth, fbRef } from '../firebase'
import { UserRole } from '../types'
import { validateEmail } from '../email'

const log = debug('AuthContainer.tsx')

export interface IAuthContainerState {
  user?: firebase.UserInfo
  role?: UserRole
  isLoggedIn: boolean
  isLoaded: boolean
}

interface LoginProps {
  email: string;
  password: string;
}

export interface ILoginError {
  error: boolean;
  field?: 'email' | 'password' | 'global';
  message?: string;
}

export default class AuthContainer extends Container<IAuthContainerState> {
  constructor() {
    super()

    this.state = {
      isLoaded: false,
      isLoggedIn: false,
      role: undefined,
      user: undefined,
    }

    fbAuth.onAuthStateChanged(async auth => {

      if (auth) {
        const userJson = auth.toJSON() as firebase.UserInfo

        const user = new User(userJson.uid)

        const role = await user.getRole();

        if (role !== "admin" && role !== "client") return;
      }

      this.setState({ isLoaded: true })

      if (auth) {
        log('Usuário logou.')
        const userJson = auth.toJSON() as firebase.UserInfo

        // Adicionar state com os dados do usuário
        this.setState({ user: userJson, isLoggedIn: true })

        // Atualizar papel do usuário. Quando terminar, seta como
        // carregamento finalizado.
        const user = new User(userJson.uid)

        user.getRole().then(role => {
          log(`Papel do usuário é ${role}`)
          this.setState({ role })
        })
      } else {
        log('Usuário deslogou.')

        // Remove informações do usuário
        this.setState({
          isLoggedIn: false,
          role: undefined,
          user: undefined,
        })
      }
    })
  }

  public logOut = () => {
    fbAuth.signOut()
  }

  public login = async ({ email, password }: LoginProps): Promise<ILoginError> => {
    try {
      if (!email) {
        return {
          error: true,
          field: 'email',
          message: 'Campo email é obrigatório'
        }
      }

      if (!validateEmail(email)) {
        return {
          error: true,
          field: 'email',
          message: 'Não autorizado'
        }
      }

      if (!password) {
        return {
          error: true,
          field: 'password',
          message: 'Campo senha é obrigatório'
        }
      }

      await fbAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
      const userCredential = await fbAuth.signInWithEmailAndPassword(email, password);

      const userJson = userCredential.user?.toJSON() as firebase.UserInfo;
      if (userJson) {
        const snap = await fbRef
          .child("users/roles")
          .child(userJson.uid)
          .once("value");

        const role = snap.val();

        if (role !== "admin" && role !== "client") {
          await fbAuth.signOut();
          return {
            error: true,
            field: "global",
            message: "Não autorizado",
          };
        }
      }

      return {
        error: false
      }
    } catch (e) {
      log(`Erro ao fazer login ${e}`)

      return {
        error: true,
        field: 'global',
        message: 'Não autorizado'
      }
    }
  }
}

export interface IWithAuthProps {
  auth: Container<IAuthContainerState>
}

/**
 * Adiciona uma propriedade 'auth' ao componente, com o status de login do
 * usuário.
 * @param Comp Componente a ser decorado
 */
export const withAuth = <Pwa extends object & IWithAuthProps>(
  Comp: React.ComponentType<any>,
): React.FunctionComponent<Subtract<Pwa, IWithAuthProps>> => {
  const Component = (props: Subtract<Pwa, IWithAuthProps>) => (
    <Subscribe to={[AuthContainer]}>
      {auth => <Comp {...props} auth={auth} />}
    </Subscribe>
  )
  return Component
}

/**
 * Verifica se o usuário está autenticado. Se não estiver, redireciona para
 * a página de login.
 */
export const requireAuth = compose(
  // Adiciona Auth
  withAuth,
  // Redireciona se não está logado
  withFilter(
    props => getKey('auth.state.isLoggedIn', props),
    <Redirect to="/auth" />,
  ),
)

/**
 * Verifica se o usuário não está autenticado. Se estiver, redireciona para
 * a página inicial.
 */
export const requireUnauth = compose(
  // Adiciona Auth
  withAuth,
  // Redireciona se está logado
  withFilter(
    props => !getKey('auth.state.isLoggedIn', props),
    <Redirect to="/unitsEconomics" />,
  ),
)
